import { ReactElement } from 'react';

import { Card, FormInput, FormSelect, TextArea } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';
import { EditErrorConfigFormProps } from '@/hooks/forms/useHealthErrorCustomizationConfigForm';
import { useShouldShowErrorEligibilityConfig } from '@/hooks/useShouldShowErrorEligibilityConfig';

interface Props {
	formProps: EditErrorConfigFormProps;
	onFocus?: () => void;
}

export default function ErrorEligibilityConfig({ formProps, onFocus }: Props): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();
	const { shouldShowEligibilityErrorButton } = useShouldShowErrorEligibilityConfig();

	const eligibilityErrorButtonTypeOptions = [
		{ value: '', label: 'Type' },
		{ value: 'email', label: 'Email' },
		{ value: 'phone', label: 'Phone' },
		{ value: 'url', label: 'URL' },
	];

	const errorBody = formProps.bindWithErrorProps('error_body', 'text');
	const errorButtonText = formProps.bindWithErrorProps('error_button_text', 'text');
	const errorButtonType = formProps.bindWithErrorProps('error_button_type', 'select');
	const errorButtonData = formProps.bindWithErrorProps('error_button_data', 'text');

	const isDisabled = !hasValidPermissions('error_configs', [actions.UPDATE]);

	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>Eligibility Error Customization</CellTitle>
			<Subtitle>
				You can customize the messaging and contact information in the eligibility error screens in order to
				direct your members towards the appropriate support channel.
			</Subtitle>
			<TextArea
				{...errorBody}
				label="Body (Limit 250 characters)"
				placeholder="Leave blank to set default"
				data-testid="error-body-text"
				disabled={isDisabled}
			/>
			{shouldShowEligibilityErrorButton && (
				<>
					<FormInput {...errorButtonText} label="Button Text" disabled={isDisabled} />
					<FormSelect
						options={eligibilityErrorButtonTypeOptions}
						{...errorButtonType}
						placeholder="Type"
						aria-label="Button Type"
						disabled={isDisabled}
					/>
					<FormInput {...errorButtonData} label="Data" disabled={isDisabled} />{' '}
				</>
			)}
		</Card>
	);
}
