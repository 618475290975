import { ReactElement, useCallback, useMemo } from 'react';

import { FormInput, PrimaryButton, FormSelect, IconButton, units, TextArea } from '@calm-web/design-system';
import { CircleMinus } from '@calm-web/icons';

import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth';
import { EditHealthReferralFormProps } from '@/hooks/forms/useHealthReferralForm';
import { HealthReferralDefinitions } from '@/types/health';

import UnifiedLogoUploader from '../../../PartnerBranding/LogoUploader/UnifiedLogoUploader';
import { FlexDiv, LinkOutTitle } from '../../styles';

function ReferralCardInner({
	formProps,
	selectedTab,
	shouldHideValidation = false,
	onFocusOverview,
	onFocusLinkout,
}: {
	formProps: EditHealthReferralFormProps;
	selectedTab: HealthReferralDefinitions;
	shouldHideValidation?: boolean;
	onFocusOverview?: () => void;
	onFocusLinkout?: () => void;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const isEdit = hasValidPermissions('health_sponsorship_referrals', [actions.UPDATE]);
	const titleText = useMemo(() => {
		return {
			[HealthReferralDefinitions.PHQ_0]: 'GAD and PHQ = 0 (Asymptomatic)',
			[HealthReferralDefinitions.PHQ_1_4]: 'GAD or PHQ = 1 - 4 (Sub-clinical)',
			[HealthReferralDefinitions.PHQ_5_9]: 'GAD or PHQ = 5 - 9 (Mild)',
			[HealthReferralDefinitions.PHQ_10_14]: 'GAD or PHQ = 10 - 14 (Moderate)',
			[HealthReferralDefinitions.PHQ_15]: 'GAD or PHQ = 15+ (Severe)',
			[HealthReferralDefinitions.CRISIS]: 'Crisis',
		}[selectedTab];
	}, [selectedTab]);

	const options = [
		{ value: 'sms', label: 'Phone SMS' },
		{ value: 'phone', label: 'Phone Call' },
		{ value: 'url', label: 'Link' },
	];

	const overrideOptions = [
		{ value: '0', label: 'None (Default)' },
		{ value: '1', label: ' Override 1' },
		{ value: '2', label: ' Override 2' },
		{ value: '3', label: ' Override 3' },
		{ value: '4', label: ' Override 4' },
		{ value: '5', label: ' Override 5' },
		{ value: '6', label: ' Override 6' },
	];

	const onRemoveLinkOut = useCallback(
		(index: 2 | 3) => {
			formProps.setProperty(`show_link_out_${index}`, 'false');
		},
		[formProps],
	);

	const showLinkOut2 = formProps.model.show_link_out_2 === 'true';
	const showLinkOut3 = formProps.model.show_link_out_3 === 'true';

	const titleFormProps = formProps.bindWithErrorProps('title', 'text');
	const bannerTitleFormProps = formProps.bindWithErrorProps('banner_title', 'text');
	const bannerSubtitleFormProps = formProps.bindWithErrorProps('banner_subtitle', 'text');
	const descriptionFormProps = formProps.bindWithErrorProps('description', 'text');
	const linkOut1TextFormProps = formProps.bindWithErrorProps('link_out_1_text', 'text');
	const linkOut1DataFormProps = formProps.bindWithErrorProps('link_out_1_data', 'text');
	const linkOut2TextFormProps = formProps.bindWithErrorProps('link_out_2_text', 'text');
	const linkOut2DataFormProps = formProps.bindWithErrorProps('link_out_2_data', 'text');
	const linkOut3TextFormProps = formProps.bindWithErrorProps('link_out_3_text', 'text');
	const linkOut3DataFormProps = formProps.bindWithErrorProps('link_out_3_data', 'text');
	const showValidation = shouldHideValidation ? { showValidation: false } : {};

	return (
		<>
			<div onFocus={onFocusOverview} onClick={onFocusOverview} style={{ marginBottom: units(2) }}>
				<CellTitle data-testid="referral-form-title">{titleText}</CellTitle>
				{selectedTab === 'crisis-response' && (
					<FormInput {...titleFormProps} label="Title" disabled={!isEdit} {...showValidation} />
				)}
				<FormInput {...bannerTitleFormProps} label="Banner Title" disabled={!isEdit} {...showValidation} />
				<FormInput
					{...bannerSubtitleFormProps}
					label="Banner Subtitle"
					disabled={!isEdit}
					{...showValidation}
				/>
				<UnifiedLogoUploader
					formProps={formProps}
					fileFieldName="uploaded_logo"
					urlFieldName="logo_url"
					analyticsEvent="Partner : Logo Upload : Referral : Selected"
					hideDelete
					allowDuplicateSelect
					maxImageSizeMB={5}
					supportPNG={false}
					disabled={!isEdit}
				/>
			</div>
			<div onFocus={onFocusLinkout} onClick={onFocusLinkout}>
				<TextArea
					{...descriptionFormProps}
					label="Recommendation Description"
					disabled={!isEdit}
					{...showValidation}
				/>
				<LinkOutTitle>Link Out 1</LinkOutTitle>
				<FormInput {...linkOut1TextFormProps} label="Text" disabled={!isEdit} {...showValidation} />
				<FormSelect
					options={options}
					{...formProps.bindWithErrorProps('link_out_1_type', 'select')}
					label="Type"
					placeholder="Type"
					disabled={!isEdit}
					{...showValidation}
				/>
				<FormInput {...linkOut1DataFormProps} label="Data" disabled={!isEdit} {...showValidation} />
				<FormSelect
					options={overrideOptions}
					{...formProps.bindWithErrorProps('link_out_1_override', 'select')}
					label="Recommendation Override"
					placeholder="Override"
					disabled={!isEdit}
					{...showValidation}
				/>

				{!showLinkOut2 && isEdit && (
					<PrimaryButton onPress={() => formProps.setProperty('show_link_out_2', 'true')}>
						Add Link Out
					</PrimaryButton>
				)}
				{showLinkOut2 && (
					<>
						<FlexDiv>
							<LinkOutTitle>Link Out 2</LinkOutTitle>
							{!showLinkOut3 && isEdit && (
								<IconButton
									Icon={CircleMinus}
									color="error"
									aria-label="Remove Link Out 2 Fields"
									onPress={() => onRemoveLinkOut(2)}
								/>
							)}
						</FlexDiv>
						<FormInput {...linkOut2TextFormProps} label="Text" disabled={!isEdit} {...showValidation} />
						<FormSelect
							options={options}
							{...formProps.bindWithErrorProps('link_out_2_type', 'select')}
							label="Type"
							placeholder="Type"
							disabled={!isEdit}
							{...showValidation}
						/>
						<FormInput {...linkOut2DataFormProps} label="Data" disabled={!isEdit} {...showValidation} />
						<FormSelect
							options={overrideOptions}
							{...formProps.bindWithErrorProps('link_out_2_override', 'select')}
							label="Recommendation Override"
							placeholder="Override"
							disabled={!isEdit}
							{...showValidation}
						/>
					</>
				)}
				{!showLinkOut3 && showLinkOut2 && isEdit && (
					<PrimaryButton onPress={() => formProps.setProperty('show_link_out_3', 'true')}>
						Add Link Out
					</PrimaryButton>
				)}

				{showLinkOut3 && (
					<>
						<FlexDiv>
							<LinkOutTitle>Link Out 3</LinkOutTitle>
							{isEdit && (
								<IconButton
									Icon={CircleMinus}
									color="error"
									aria-label="Remove Link Out 3 Fields"
									onPress={() => onRemoveLinkOut(3)}
								/>
							)}
						</FlexDiv>
						<FormInput {...linkOut3TextFormProps} label="Text" disabled={!isEdit} {...showValidation} />
						<FormSelect
							options={options}
							{...formProps.bindWithErrorProps('link_out_3_type', 'select')}
							label="Type"
							placeholder="Type"
							disabled={!isEdit}
							{...showValidation}
						/>
						<FormInput {...linkOut3DataFormProps} label="Data" disabled={!isEdit} {...showValidation} />
						<FormSelect
							options={overrideOptions}
							{...formProps.bindWithErrorProps('link_out_3_override', 'select')}
							label="Recommendation Override"
							placeholder="Override"
							disabled={!isEdit}
							{...showValidation}
						/>
					</>
				)}
			</div>
		</>
	);
}

export default ReferralCardInner;
