import { useFeatureFlags } from './api/useFeatureFlags';

export const ERROR_ELIGIBILITY_CONFIG = 'b2b-ch-error-eligibility-config';
export const ELIGIBILITY_ERROR_BUTTON = 'b2b-ch-eligibility-error-button';

interface Response {
	shouldShowErrorEligibilityConfig: boolean;
	shouldShowEligibilityErrorButton: boolean;
}

export const useShouldShowErrorEligibilityConfig = (): Response => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(ERROR_ELIGIBILITY_CONFIG, ELIGIBILITY_ERROR_BUTTON);
	const flagsLoaded = !flagLoading && Boolean(!flagError) && Boolean(flagValues);
	const shouldShowErrorEligibilityConfig = flagsLoaded && flagValues?.[ERROR_ELIGIBILITY_CONFIG] === true;
	const shouldShowEligibilityErrorButton = flagsLoaded && flagValues?.[ELIGIBILITY_ERROR_BUTTON] === true;
	return {
		shouldShowErrorEligibilityConfig,
		shouldShowEligibilityErrorButton,
	};
};
