import { capitalize } from 'lodash';
import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';

import { IconButton, Loader, PrimaryButton } from '@calm-web/design-system';

import Table from '@/components/ui/Table';
import { useBillingHistory } from '@/hooks/api/useBillingHistory';
import { useRefundInvoice } from '@/hooks/api/useInvoices';
import { useIsAdmin } from '@/hooks/auth/useAuth';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { BillingHistoryI } from '@/types/billingHistory';
import { Partner } from '@/types/store/reducers';
import { currencyStringFromDollars } from '@/utils/helpers';

import DownloadIcon from 'icons/download.svg';

import messages from './messages';
import {
	SectionContainer,
	Title,
	MinHeightCell,
	MobileWrapper,
	MobileDataColumn,
	MobileLabel,
	BillingHistoryDataWrapper,
} from './styles';

interface Props {
	partner: Partner;
}

function RefundCell({
	original,
	value,
	partnerId,
	refundedEvents,
}: {
	original: BillingHistoryI;
	value: string;
	partnerId: string;
	refundedEvents: Set<string>;
}): ReactElement {
	const [refundInvoiceEvent, { loading }] = useRefundInvoice(partnerId, value);
	const handleClick = (): Promise<void> => refundInvoiceEvent();

	const isStripeCredit = original.is_credit;
	const refundId = original.payment_intent_id;
	const alreadyRefunded = refundedEvents?.has(refundId ?? '');
	const isPartialRefund = original.is_partial_refund;
	const isOneOffPayment = original.event_type === 'one_off_payment';

	if (isOneOffPayment || isPartialRefund || isStripeCredit || alreadyRefunded) {
		return <MinHeightCell></MinHeightCell>;
	}

	return (
		<PrimaryButton size={'sm'} isLoading={loading} onPress={handleClick}>
			Refund
		</PrimaryButton>
	);
}

export default function BillingHistory({ partner }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const [isMobile] = useIsMobile();

	const { data: billingHistory, loading: loading, error: error } = useBillingHistory(partner.id);

	const refundedEvents = useMemo(() => {
		if (!billingHistory) {
			return new Set<string>();
		}

		const billingHistoryRefundEvents = billingHistory?.filter(event => event.refunded === true);
		const refundPaymentIds = billingHistoryRefundEvents?.map(event => event.payment_intent_id);
		const filteredRefundPaymentIds = refundPaymentIds?.filter(id => id !== null);

		return new Set(filteredRefundPaymentIds);
	}, [billingHistory]);

	const isAdmin = useIsAdmin();

	function DateCell({ value }: { value: string }): ReactElement {
		const formattedDate = new Date(value).toLocaleString(undefined, {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'UTC',
		});
		return <div data-testid="billing-history-date">{formattedDate.toLowerCase()}</div>;
	}

	function AmountCell({ value }: { value: number }): ReactElement {
		return <div data-testid="billing-history-amount">{currencyStringFromDollars(value / 100)}</div>;
	}

	function TypeCell({
		value,
		refunded,
		isPartialRefund,
		isCredit,
	}: {
		value: string | boolean;
		refunded: boolean | undefined;
		isPartialRefund: boolean | undefined;
		isCredit: boolean | undefined;
	}): ReactElement {
		let typeText = '';

		const combinedValue =
			value.toString() +
			(refunded ? '_refunded' : isPartialRefund ? '_partial_refund' : isCredit ? '_stripeCredit' : '');

		switch (combinedValue) {
			case 'one_off_payment':
				typeText = 'One-off payment';
				break;
			case 'one_off_payment_refunded':
				typeText = 'One-off payment (Refunded)';
				break;
			case 'one_off_payment_partial_refund':
				typeText = 'One-off payment (Payment after partial refund)';
				break;
			case 'subscription_create':
			case 'subscription_cycle':
				typeText = 'Annual bill';
				break;
			case 'subscription_create_partial_refund':
			case 'subscription_cycle_partial_refund':
				typeText = 'Annual bill (Payment after partial refund)';
				break;
			case 'subscription_create_refunded':
			case 'subscription_cycle_refunded':
				typeText = 'Annual bill (Refunded)';
				break;
			case 'subscription_update':
				typeText = 'Pro-rated charge';
				break;
			case 'subscription_update_partial_refund':
				typeText = 'Pro-rated charge (Payment after partial refund)';
				break;
			case 'subscription_update_refunded':
				typeText = 'Pro-rated charge (Refunded)';
				break;
			case 'subscription_create_stripeCredit':
			case 'subscription_cycle_stripeCredit':
			case 'subscription_update_stripeCredit':
				typeText = 'Stripe credit';
				break;
			default:
				typeText = 'Billing event';
		}
		return <div data-testid="billing-history-event-type">{typeText}</div>;
	}

	function Quantity({ value }: { value: number | null | undefined }): ReactElement {
		return <div data-testid="billing-history-quantity">{value ?? ''}</div>;
	}

	function StatusCell({ value }: { value: string | null | undefined }): ReactElement {
		return <div data-testid="billing-history-status">{capitalize(value ?? '')}</div>;
	}

	function InvoiceCell({ value }: { value: string }): ReactElement {
		return (
			<div data-testid="billing-history-invoice-download">
				{value && <IconButton color="blue3" aria-label="Download invoice" Icon={DownloadIcon} href={value} />}
			</div>
		);
	}

	function ReceiptCell({ value }: { value: string | null }): ReactElement {
		if (!value) {
			return <MinHeightCell />;
		}
		return (
			<div data-testid="billing-history-receipt">
				{value && <IconButton color="blue3" aria-label="Download receipt" Icon={DownloadIcon} href={value} />}
			</div>
		);
	}

	const billingHistoryColumns: Column<BillingHistoryI>[] = useMemo(() => {
		function typeCellBillingHistory({ row }: { row: { original: BillingHistoryI } }): ReactElement {
			const { refunded, event_type, is_partial_refund, is_credit } = row.original;
			return (
				<TypeCell
					value={event_type}
					refunded={refunded}
					isPartialRefund={is_partial_refund}
					isCredit={is_credit}
				/>
			);
		}

		const cells = [
			{ Header: 'Date', accessor: 'created_at' as const, Cell: DateCell },
			{ Header: 'Type', accessor: 'event_type' as const, Cell: typeCellBillingHistory },
			{ Header: 'Amount', accessor: 'amount' as const, Cell: AmountCell },
			{ Header: 'Lives Covered', accessor: 'quantity' as const, Cell: Quantity },
			{ Header: 'Invoice', accessor: 'invoice_url' as const, Cell: InvoiceCell },
			{ Header: 'Receipt', accessor: 'receipt_url' as const, Cell: ReceiptCell },
			{
				Header: 'Status',
				accessor: (row: BillingHistoryI) => row.invoice_status ?? row.charge_status,
				Cell: StatusCell,
			},
		];

		if (isAdmin) {
			return [
				...cells,
				{
					Header: 'Refund',
					accessor: 'id' as const,
					Cell: ({
						row: { original },
						value,
					}: {
						row: {
							original: BillingHistoryI;
						};
						value: string;
					}) => (
						<RefundCell
							original={original}
							value={value}
							partnerId={partner.id}
							refundedEvents={refundedEvents}
						/>
					),
				},
			];
		}

		return cells;
	}, [isAdmin, partner.id, refundedEvents]);

	return (
		<SectionContainer>
			<Title>
				<>{formatMessage(messages.billingHistory)}</>
				{loading && <Loader color="gray1" />}
			</Title>
			{billingHistory && (
				<>
					{isMobile ? (
						<BillingHistoryDataWrapper>
							{billingHistory.map((item: BillingHistoryI): ReactElement => {
								const {
									id,
									created_at,
									event_type,
									amount,
									quantity,
									invoice_url,
									refunded,
									receipt_url,
									is_partial_refund,
									is_credit,
									invoice_status,
									charge_status,
								} = item;
								return (
									<MobileWrapper key={id}>
										<MobileDataColumn>
											<section>
												<MobileLabel>Date</MobileLabel>
												<DateCell value={created_at} />
											</section>
											<section>
												<MobileLabel>Type</MobileLabel>
												<TypeCell
													value={event_type}
													refunded={refunded}
													isPartialRefund={is_partial_refund}
													isCredit={is_credit}
												/>
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Amount</MobileLabel>
												<AmountCell value={amount} />
											</section>
											<section>
												<MobileLabel>Lives Covered</MobileLabel>
												<Quantity value={quantity} />
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Invoice</MobileLabel>
												{invoice_url && <InvoiceCell value={invoice_url} />}
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Receipt</MobileLabel>
												{receipt_url && <ReceiptCell value={receipt_url ?? null} />}
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Status</MobileLabel>
												<StatusCell value={invoice_status ?? charge_status} />
											</section>
										</MobileDataColumn>
										{isAdmin && (
											<section>
												<RefundCell
													original={item}
													value={id}
													partnerId={partner.id}
													refundedEvents={refundedEvents}
												/>
											</section>
										)}
									</MobileWrapper>
								);
							})}
						</BillingHistoryDataWrapper>
					) : (
						<Table
							columns={billingHistoryColumns}
							data={billingHistory || []}
							fetchData={(): void => undefined}
							loading={loading}
							pageCount={1}
							error={error?.message}
							dataTestId="billing-history-table"
							alignHeadingsToText
							cellVerticalAlign="middle"
						/>
					)}
				</>
			)}
		</SectionContainer>
	);
}
