import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import useForm, { FormProps, stringFromModelValue, validation } from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';
import { HealthReferral, HealthReferralDefinitions, REFERRAL_DEFN_ID_ARRAY } from '@/types/health';

type FieldNames =
	| 'id'
	| 'banner_title'
	| 'banner_subtitle'
	| 'logo_url'
	| 'uploaded_logo'
	| 'description'
	| 'link_out_1_text'
	| 'link_out_1_type'
	| 'link_out_1_data'
	| 'link_out_1_override'
	| 'show_link_out_2'
	| 'link_out_2_text'
	| 'link_out_2_type'
	| 'link_out_2_data'
	| 'link_out_2_override'
	| 'show_link_out_3'
	| 'link_out_3_text'
	| 'link_out_3_type'
	| 'link_out_3_data'
	| 'link_out_3_override'
	| 'title';

export type EditHealthReferralFormProps = FormProps<FieldNames>;

const useHealthReferralAttributeForm = (
	referralDefinitionID: HealthReferralDefinitions,
	referral?: HealthReferral,
): {
	formProps: EditHealthReferralFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const gadPHQString = useMemo(() => {
		return {
			[HealthReferralDefinitions.PHQ_0]: 'GAD and PHQ = 0',
			[HealthReferralDefinitions.PHQ_1_4]: 'GAD or PHQ = 1 - 4',
			[HealthReferralDefinitions.PHQ_5_9]: 'GAD or PHQ = 5 - 9',
			[HealthReferralDefinitions.PHQ_10_14]: 'GAD or PHQ = 10 - 14',
			[HealthReferralDefinitions.PHQ_15]: 'GAD or PHQ = 15+',
			[HealthReferralDefinitions.CRISIS]: 'Crisis',
		}[referralDefinitionID];
	}, [referralDefinitionID]);
	const formProps = useForm(`healthReferralForm-${referralDefinitionID}`, {
		initialModel: {
			id: referral?.id ?? '',
			banner_title: referral?.banner_title ?? '',
			banner_subtitle: referral?.banner_subtitle ?? '',
			description: referral?.description ?? '',
			logo_url: referral?.logo_url ?? '',
			uploaded_logo: [],
			link_out_1_text: referral?.link_out_1_text ?? '',
			link_out_1_type: referral?.link_out_1_type ?? '',
			link_out_1_data: referral?.link_out_1_data ?? '',
			link_out_1_override: referral?.link_out_1_override?.toString() ?? '',
			show_link_out_2: referral?.link_out_2_text ? 'true' : 'false',
			link_out_2_text: referral?.link_out_2_text ?? '',
			link_out_2_type: referral?.link_out_2_type ?? '',
			link_out_2_data: referral?.link_out_2_data ?? '',
			link_out_2_override: referral?.link_out_2_override?.toString() ?? '',
			show_link_out_3: referral?.link_out_3_text ? 'true' : 'false',
			link_out_3_text: referral?.link_out_3_text ?? '',
			link_out_3_type: referral?.link_out_3_type ?? '',
			link_out_3_data: referral?.link_out_3_data ?? '',
			link_out_3_override: referral?.link_out_3_override?.toString() ?? '',
			title: referral?.title ?? '',
		},
		validation: {
			banner_title: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: `Please enter a Banner Title for the ${gadPHQString} Recommendation.`,
				},
			]),
			banner_subtitle: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: `Please enter a Banner Subtitle for the ${gadPHQString} Recommendation.`,
				},
			]),
			description: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: `Please enter a Description for the ${gadPHQString} Recommendation.`,
				},
			]),
			uploaded_logo: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value?.[0]) || Boolean(props.model.logo_url);
						},
					],
					errorResult: `Please upload a logo or for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_1_text: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: `Please complete Link Out 1 for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_1_type: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: `Please complete Link Out 1 for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_1_data: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: `Please complete Link Out 1 for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_2_text: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value) || props.model.show_link_out_2 === 'false';
						},
					],
					errorResult: `Please complete Link Out 2 or remove for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_2_type: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value) || props.model.show_link_out_2 === 'false';
						},
					],
					errorResult: `Please complete Link Out 2 or remove for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_2_data: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value) || props.model.show_link_out_2 === 'false';
						},
					],
					errorResult: `Please complete Link Out 2 or remove for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_3_text: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value) || props.model.show_link_out_3 === 'false';
						},
					],
					errorResult: `Please complete Link Out 3 or remove for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_3_type: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value) || props.model.show_link_out_3 === 'false';
						},
					],
					errorResult: `Please complete Link Out 3 or remove for the ${gadPHQString} Recommendation.`,
				},
			]),
			link_out_3_data: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							return Boolean(value) || props.model.show_link_out_3 === 'false';
						},
					],
					errorResult: `Please complete Link Out 3 or remove for the ${gadPHQString} Recommendation.`,
				},
			]),
			title: validation.validateOrFail([
				{
					rules: [
						value => {
							return Boolean(value) || referralDefinitionID !== 'crisis-response';
						},
					],
					errorResult: 'Please enter a title for the crisis recommendation.',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export const useHealthReferralForms = (
	referrals: HealthReferral[] | undefined,
): {
	healthReferralFormProps: EditHealthReferralFormProps[];
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const healthReferralFormPHQ_0 = useHealthReferralAttributeForm(
		HealthReferralDefinitions.PHQ_0,
		referrals?.[0],
	);
	const healthReferralFormPHQ_1_4 = useHealthReferralAttributeForm(
		HealthReferralDefinitions.PHQ_1_4,
		referrals?.[1],
	);
	const healthReferralFormPHQ_5_9 = useHealthReferralAttributeForm(
		HealthReferralDefinitions.PHQ_5_9,
		referrals?.[2],
	);
	const healthReferralFormPHQ_10_14 = useHealthReferralAttributeForm(
		HealthReferralDefinitions.PHQ_10_14,
		referrals?.[3],
	);
	const healthReferralFormPHQ_15 = useHealthReferralAttributeForm(
		HealthReferralDefinitions.PHQ_15,
		referrals?.[4],
	);
	const healthReferralFormCrisis = useHealthReferralAttributeForm(
		HealthReferralDefinitions.CRISIS,
		referrals?.[5],
	);

	const healthReferralForms = useMemo(
		() => [
			healthReferralFormPHQ_0,
			healthReferralFormPHQ_1_4,
			healthReferralFormPHQ_5_9,
			healthReferralFormPHQ_10_14,
			healthReferralFormPHQ_15,
			healthReferralFormCrisis,
		],
		[
			healthReferralFormPHQ_0,
			healthReferralFormPHQ_1_4,
			healthReferralFormPHQ_5_9,
			healthReferralFormPHQ_10_14,
			healthReferralFormPHQ_15,
			healthReferralFormCrisis,
		],
	);

	const healthReferralFormProps = useMemo(
		() => healthReferralForms.map(form => form.formProps),
		[healthReferralForms],
	);

	const hasChangedAny = healthReferralForms.some(props => props.hasChangedAny);
	const hasTouchedAny = healthReferralForms.some(props => props.hasTouchedAny);

	return {
		healthReferralFormProps,
		hasChangedAny,
		hasTouchedAny,
	};
};

const getErrorMessage = (props: EditHealthReferralFormProps): string | undefined => {
	return stringFromModelValue(
		(Object.keys(props.validation.fields) as FieldNames[])
			.map(fieldName => stringFromModelValue(props.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const areHealthReferralsEmpty = (allHealthReferralProps: EditHealthReferralFormProps[]): boolean => {
	return allHealthReferralProps.every(healthReferralProps => {
		return Object.keys(healthReferralProps.model).every(key => {
			const value = stringFromModelValue(healthReferralProps.model[key as FieldNames]);
			return !value || value === 'false';
		});
	});
};

export const isHealthReferralValid = (
	allHealthReferralFormProps: EditHealthReferralFormProps[],
	healthReferralProps: EditHealthReferralFormProps,
	isDependendentGroup = false,
): boolean => {
	return (
		healthReferralProps.validation.isValid ||
		(isDependendentGroup && areHealthReferralsEmpty(allHealthReferralFormProps))
	);
};

export const useHealthReferralSubmitData = (
	healthReferralPropsArray: EditHealthReferralFormProps[],
): {
	getHealthReferralSubmitData: () => Partial<HealthReferral>[];
	showValidationErrors: (isDependendentGroup?: boolean) => boolean;
} => {
	const dispatch = useDispatch();

	const getHealthReferralSubmitData = useCallback((): Partial<HealthReferral>[] => {
		const baseReferralData: Partial<HealthReferral>[] = REFERRAL_DEFN_ID_ARRAY.map((defn_id, index) => {
			const uploaded_logo = healthReferralPropsArray[index].model.uploaded_logo?.[0] as File | undefined;
			return {
				id: stringFromModelValue(healthReferralPropsArray[index].model.id),
				referral_defn_id: REFERRAL_DEFN_ID_ARRAY[index],
				banner_title: stringFromModelValue(healthReferralPropsArray[index].model.banner_title),
				banner_subtitle: stringFromModelValue(healthReferralPropsArray[index].model.banner_subtitle),
				description: stringFromModelValue(healthReferralPropsArray[index].model.description),
				link_out_1_text: stringFromModelValue(healthReferralPropsArray[index].model.link_out_1_text),
				link_out_1_type: stringFromModelValue(healthReferralPropsArray[index].model.link_out_1_type),
				link_out_1_data: stringFromModelValue(healthReferralPropsArray[index].model.link_out_1_data),
				link_out_1_override: parseInt(
					stringFromModelValue(healthReferralPropsArray[index].model.link_out_1_override) || '0',
				),
				link_out_2_text:
					healthReferralPropsArray[index].model.show_link_out_2 === 'true'
						? stringFromModelValue(healthReferralPropsArray[index].model.link_out_2_text)
						: '',
				link_out_2_type:
					healthReferralPropsArray[index].model.show_link_out_2 === 'true'
						? stringFromModelValue(healthReferralPropsArray[index].model.link_out_2_type)
						: null,
				link_out_2_data:
					healthReferralPropsArray[index].model.show_link_out_2 === 'true'
						? stringFromModelValue(healthReferralPropsArray[index].model.link_out_2_data)
						: '',
				link_out_2_override: parseInt(
					stringFromModelValue(healthReferralPropsArray[index].model.link_out_2_override) || '0',
				),
				link_out_3_text:
					healthReferralPropsArray[index].model.show_link_out_3 === 'true'
						? stringFromModelValue(healthReferralPropsArray[index].model.link_out_3_text)
						: '',
				link_out_3_type:
					healthReferralPropsArray[index].model.show_link_out_3 === 'true'
						? stringFromModelValue(healthReferralPropsArray[index].model.link_out_3_type)
						: null,
				link_out_3_data:
					healthReferralPropsArray[index].model.show_link_out_3 === 'true'
						? stringFromModelValue(healthReferralPropsArray[index].model.link_out_3_data)
						: '',
				link_out_3_override: parseInt(
					stringFromModelValue(healthReferralPropsArray[index].model.link_out_3_override) || '0',
				),
				...(uploaded_logo && { uploaded_logo }),
				...(defn_id === HealthReferralDefinitions.CRISIS && {
					title: stringFromModelValue(healthReferralPropsArray[index].model.title),
				}),
			};
		});

		return baseReferralData;
	}, [healthReferralPropsArray]);

	const showValidationErrors = useCallback(
		(isDependendentGroup = false): boolean => {
			if (
				healthReferralPropsArray.every(formProps =>
					isHealthReferralValid(healthReferralPropsArray, formProps, isDependendentGroup),
				)
			) {
				return false;
			}
			healthReferralPropsArray.forEach(formProps => {
				const errorMessage = getErrorMessage(formProps);
				if (errorMessage) {
					dispatch(
						setBannerMessage({
							message: `Error: ${errorMessage}`,
							flash: true,
							isError: true,
						}),
					);
				}
			});
			return true;
		},
		[dispatch, healthReferralPropsArray],
	);

	return { getHealthReferralSubmitData, showValidationErrors };
};
