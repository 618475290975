import { defineMessages } from 'react-intl';

export default defineMessages({
	bannerTitle: {
		id: 'referralBanner.title',
		defaultMessage: 'Get {giftValue}!',
		description: 'Title text for the referral banner.',
	},
	bannerBody: {
		id: 'referralBanner.body',
		defaultMessage: 'When you refer a colleague to Calm.',
		description: 'Body text for the referral banner.',
	},
	bannerCta: {
		id: 'referralBanner.cta',
		defaultMessage: 'Get Started!',
		description: 'Call to action button text for the referral banner.',
	},
});
