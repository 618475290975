import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { BillingOperations, PaymentInfo } from '@/components/pages/Plan/PlanDetails/types';
import { setBannerMessage } from '@/store/actions';
import { isSelfServePlan } from '@/utils/SkuUtils';

import messages from './messages';
import { BannerTextButton } from './styles';
import { useNavToPlanPageAndOpenUpdatePaymentDetailsModal } from './useNavToPlanPageAndOpenUpdatePaymentDetailsModal';
import { useDefinedPartner } from './usePartner';
import { usePaymentInfo } from './usePaymentInfo';

export enum AchModalKeys {
	PROCESSING = 'did-show-ach-payment-method-processing-modal',
	SUCCEEDED = 'did-show-ach-payment-method-succeeded-modal',
}

interface UseActivateAchPaymentMethodLifeCycleManagement {
	isLoading: boolean;
	ui: {
		paymentDetails: {
			error: string | undefined;
		};
		banner: {
			error: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			pendingCopy: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
		};
		modal:
			| {
					title: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
					bodyText: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
					buttonText: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
					showConfetti: boolean;
			  }
			| undefined;
	};
	achModalKey: AchModalKeys | null;
	intentStatus: PaymentInfo['intent_status'] | null;
}

export const billingOpsThatCancelSubOnFail = new Set([
	BillingOperations.SUBSCRIPTION_CREATION,
	BillingOperations.SUBSCRIPTION_REACTIVATION,
	BillingOperations.SUBSCRIPTION_RENEWAL,
]);

export const useActivateAchPaymentMethodLifeCycleManagement =
	(): UseActivateAchPaymentMethodLifeCycleManagement => {
		const { formatMessage } = useIntl();
		const { data: paymentInfo, isLoading: isLoadingPaymentInfo } = usePaymentInfo();
		const [bannerError, setAchPaymentMethodActivationBannerError] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['paymentDetails']['error']>();
		const [error, setAchPaymentMethodActivationError] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['paymentDetails']['error']>();
		const [pendingCopy, setPendingCopy] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['banner']['pendingCopy']>();
		const [isLoading, setIsLoading] = useState<boolean>(true);
		const [modalInfo, setModalInfo] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['modal']>();
		const [achModalKey, setAchModalKey] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['achModalKey']>(null);
		const [intentStatus, setIntentStatus] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['intentStatus']>(null);
		const dispatch = useDispatch();
		const partner = useDefinedPartner();
		const navToPlanPageAndOpenUpdatePaymentDetailsModal =
			useNavToPlanPageAndOpenUpdatePaymentDetailsModal(false);

		useEffect(() => {
			const getActivateAchPaymentMethodLifeCycleInfo = (): void => {
				try {
					if (!isSelfServePlan(partner.vouched_plan_sku) || !paymentInfo) {
						return;
					}
					setIsLoading(true);
					setAchPaymentMethodActivationError(undefined);
					setAchPaymentMethodActivationBannerError(undefined);
					setPendingCopy(undefined);
					setAchModalKey(null);
					setIntentStatus(null);
					setModalInfo(undefined);

					if (paymentInfo?.type !== 'us_bank_account') {
						return;
					}

					const intentStatus = paymentInfo.intent_status;
					const errorReason = paymentInfo.last_intent_error;
					const invoiceBillingOperation = paymentInfo.invoice_billing_operation;

					if (intentStatus === 'requires_payment_method' && errorReason) {
						if (errorReason === 'insufficient_funds') {
							setAchPaymentMethodActivationBannerError(
								formatMessage(messages.achPaymentMethodInsufficientFundsBannerError, {
									changedetails: (...chunks: React.ReactNode[]) => (
										<BannerTextButton onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
											{chunks}
										</BannerTextButton>
									),
								}) as string,
							);
							setAchPaymentMethodActivationError(
								formatMessage(messages.achPaymentMethodInsufficientFundsError),
							);
						}
					} else if (intentStatus === 'canceled') {
						if (invoiceBillingOperation && billingOpsThatCancelSubOnFail.has(invoiceBillingOperation)) {
							setAchPaymentMethodActivationBannerError(
								formatMessage(messages.achPaymentMethodProcessingFailedBannerError, {
									changedetails: (...chunks: React.ReactNode[]) => (
										<BannerTextButton onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
											{chunks}
										</BannerTextButton>
									),
								}) as string,
							);
							setAchPaymentMethodActivationError(
								formatMessage(messages.achPaymentMethodProcessingFailedError),
							);
						}
					} else if (intentStatus === 'processing') {
						setPendingCopy(formatMessage(messages.achPaymentMethodProcessing));
						if (invoiceBillingOperation === BillingOperations.SUBSCRIPTION_CREATION) {
							setModalInfo({
								title: formatMessage(messages.achProcessingTitle),
								bodyText: formatMessage(messages.achProcessingBody, {
									newline: () => <br />,
									b: chunks => <strong>{chunks}</strong>,
								}),
								buttonText: formatMessage(messages.achButtonText),
								showConfetti: false,
							});
							setAchModalKey(AchModalKeys.PROCESSING);
						}
					} else if (intentStatus === 'succeeded') {
						if (invoiceBillingOperation === BillingOperations.SUBSCRIPTION_CREATION) {
							setModalInfo({
								title: formatMessage(messages.achSucceededTitle),
								bodyText: formatMessage(messages.achSucceededBody, {
									newline: () => <br />,
									b: chunks => <strong>{chunks}</strong>,
								}),
								buttonText: formatMessage(messages.achSucceededButtonText),
								showConfetti: true,
							});
							setAchModalKey(AchModalKeys.SUCCEEDED);
						}
					}
					setIntentStatus(intentStatus);
				} catch (err) {
					dispatch(
						setBannerMessage({
							message: formatMessage(messages.getPaymentInfoError),
							isError: true,
							flash: true,
						}),
					);
					throw err;
				} finally {
					setIsLoading(false);
				}
			};

			if (!isLoadingPaymentInfo) {
				getActivateAchPaymentMethodLifeCycleInfo();
			}
		}, [
			paymentInfo,
			dispatch,
			formatMessage,
			partner,
			navToPlanPageAndOpenUpdatePaymentDetailsModal,
			isLoadingPaymentInfo,
		]);

		return {
			isLoading,
			ui: {
				paymentDetails: {
					error,
				},
				banner: {
					error: bannerError,
					pendingCopy,
				},
				modal: modalInfo,
			},
			achModalKey,
			intentStatus,
		};
	};
