import omit from 'lodash/omit';
import { ReactElement } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Card, Checkbox, RadioGroup, FormInput } from '@calm-web/design-system';
import { booleanFromCheckbox } from '@calm-web/use-form';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { PartnerCategoryType } from '@/types/store/reducers';
import { ProductSKUType, RenewalType, SHOULD_RENEW } from '@/utils/SkuEnums';
import { isCalmHealthProductSKUType } from '@/utils/SkuUtils';

import { DurationSelect } from '../IntegrationDetails/SsoConversion';
import ssoMessages from '../IntegrationDetails/SsoConversion/messages';
import messages from '../IntegrationDetails/messages';
import {
	Description,
	FixedAccessModelContainer,
	Label,
	OptionBody,
	RenewalSelection,
	RenewalTypeSelection,
	RenewUrlsContainer,
	SubscriptionAccessModelContainer,
	WithSideLabel,
} from '../IntegrationDetails/styles';
import { StyledSelect } from './styles';

function getAccessModelOptions(
	productSkuType: ProductSKUType,
): { value: string; label: MessageDescriptor }[] {
	return isCalmHealthProductSKUType(productSkuType)
		? [
				{
					value: 'custom',
					label: messages.accessModelCHContractDurationSelection,
				},
				{
					value: 'fixed',
					label: messages.accessModelCHFixedDurationSelection,
				},
		  ]
		: [
				{
					value: 'custom',
					label: messages.accessModelC4BContractDurationSelection,
				},
				{
					value: 'fixed',
					label: messages.accessModelC4BFixedDurationSelection,
				},
		  ];
}

function WebRenewalUrl({ formProps }: { formProps: EditPartnerFormProps }): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<RenewUrlsContainer>
			<Subtitle>{formatMessage(messages.renewUrlsHeader)}</Subtitle>
			<WithSideLabel>
				<Subtitle>{formatMessage(messages.webRenewUrlLabel)}</Subtitle>
				<FormInput
					{...formProps.bindWithErrorProps('webRenewUrl', 'text')}
					label={formatMessage(messages.webRenewUrlPlaceholder)}
				/>
			</WithSideLabel>
			<WithSideLabel>
				<Subtitle>{formatMessage(messages.iosRenewUrlLabel)}</Subtitle>
				<FormInput
					{...formProps.bindWithErrorProps('iosRenewUrl', 'text')}
					label={formatMessage(messages.iosRenewUrlPlaceholder)}
				/>
			</WithSideLabel>
			<WithSideLabel>
				<Subtitle>{formatMessage(messages.androidRenewUrlLabel)}</Subtitle>
				<FormInput
					{...formProps.bindWithErrorProps('androidRenewUrl', 'text')}
					label={formatMessage(messages.androidRenewUrlPlaceholder)}
				/>
			</WithSideLabel>
		</RenewUrlsContainer>
	);
}

function SubscriptionAccessModel({ formProps }: { formProps: EditPartnerFormProps }): ReactElement {
	const { formatMessage } = useIntl();

	const renewalOptions = [
		{
			id: RenewalType.Autorenew,
			value: RenewalType.Autorenew,
			label: <Label>{formatMessage(messages.autoRenewalLabel)}</Label>,
		},
		{
			id: RenewalType.Renewable,
			value: RenewalType.Renewable,
			label: <Label>{formatMessage(messages.renewableLabel)}</Label>,
		},
	];

	const radioOptions = renewalOptions;
	const defaultRadioOption = radioOptions[0].value;
	const bindWithErrorProps = formProps.bindWithErrorProps('subscriptionAccessModel', 'select');

	return (
		<Card>
			<SubscriptionAccessModelContainer>
				<CellTitle showAdminFlag id="access-model-label">
					{formatMessage(messages.subscriptionAccessTitle)}
				</CellTitle>
				<Subtitle>{formatMessage(messages.subscriptionAccessModelSubtitle)}</Subtitle>
				<div>
					<StyledSelect
						{...omit(bindWithErrorProps, ['isValid', 'showValidation'])}
						placeholder="Pick an access model"
						data-testid="access-model-select"
						aria-labelledby="access-model-label"
						disabled={formProps?.model?.category === PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP}
					>
						{getAccessModelOptions(formProps.model.productSKUType as ProductSKUType).map(
							({ value, label }) => {
								return (
									<option key={value} value={value}>
										{formatMessage(label)}
									</option>
								);
							},
						)}
					</StyledSelect>
					<Description topMargin>
						{formatMessage(
							formProps.model.subscriptionAccessModel === 'custom'
								? messages.customAccessModelDescription
								: messages.fixedAccessModelDescription,
						)}
					</Description>
					{formProps.model.subscriptionAccessModel === 'fixed' ? (
						<FixedAccessModelContainer>
							<Label id="subscription-duration-label">{formatMessage(ssoMessages.durationLabel)}</Label>
							<Subtitle>{formatMessage(ssoMessages.durationSubtitle)}</Subtitle>
							<DurationSelect formProps={formProps} />
							{formProps.model.duration !== 'lifetime' && (
								<>
									<Label>{formatMessage(messages.renewalLabel)}</Label>
									<RenewalSelection>
										<Checkbox
											{...formProps.bindWithErrorProps(SHOULD_RENEW, 'checkbox', SHOULD_RENEW)}
											id={SHOULD_RENEW}
										>
											{formatMessage(messages.renewalDescription)}
										</Checkbox>
									</RenewalSelection>
									<RenewalTypeSelection>
										<OptionBody disabled={!booleanFromCheckbox(formProps.model.shouldRenew, SHOULD_RENEW)}>
											<RadioGroup
												{...formProps.bindWithErrorProps('renewalType', 'radio')}
												aria-label="How should user subscriptions renew?"
												options={radioOptions}
												defaultValue={defaultRadioOption}
												disabled={!booleanFromCheckbox(formProps.model.shouldRenew, SHOULD_RENEW)}
											/>
										</OptionBody>
									</RenewalTypeSelection>
									<WebRenewalUrl formProps={formProps} />
								</>
							)}
						</FixedAccessModelContainer>
					) : null}
				</div>
			</SubscriptionAccessModelContainer>
		</Card>
	);
}

export default SubscriptionAccessModel;
