import { ReactElement, ReactNode, Dispatch, SetStateAction } from 'react';

import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePermissions } from '@/hooks/auth';
import { EditHealthReferralFormProps, isHealthReferralValid } from '@/hooks/forms/useHealthReferralForm';
import { HealthReferralDefinitions, REFERRAL_DEFN_ID_ARRAY } from '@/types/health';

import { StyledButton, MarginDiv, WarningIcon, TabContent, FillButton } from './styles';
import { fillAllForms } from './utils';

export function ReferralTabs({
	allHealthReferralFormProps,
	selectedTab,
	setSelectedTab,
	isDependentGroup,
}: {
	allHealthReferralFormProps: EditHealthReferralFormProps[];
	selectedTab: HealthReferralDefinitions;
	setSelectedTab: Dispatch<SetStateAction<HealthReferralDefinitions>>;
	isDependentGroup?: boolean;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const partner = useDefinedPartner();
	const tabTitle = {
		[HealthReferralDefinitions.PHQ_0]: '0',
		[HealthReferralDefinitions.PHQ_1_4]: '1 - 4',
		[HealthReferralDefinitions.PHQ_5_9]: '5 - 9',
		[HealthReferralDefinitions.PHQ_10_14]: '10 - 14',
		[HealthReferralDefinitions.PHQ_15]: '15+',
		[HealthReferralDefinitions.CRISIS]: 'Crisis',
	};

	const tabs = allHealthReferralFormProps.map((singleReferralFormProp, index) => {
		const referralDefinition = REFERRAL_DEFN_ID_ARRAY[index];
		return {
			title: tabTitle[referralDefinition],
			referralDefinition,
			isValid: isHealthReferralValid(allHealthReferralFormProps, singleReferralFormProp, isDependentGroup),
			isSelected: selectedTab === referralDefinition,
			onClick: () => setSelectedTab(referralDefinition),
		};
	});

	return (
		<MarginDiv>
			{tabs.map(tab => {
				return (
					<ReferralTab key={tab.referralDefinition} isSelected={tab.isSelected} onClick={tab.onClick}>
						<TabContent>
							{tab.title} {!tab.isValid && <WarningIcon data-testid="referral-tab-error" />}
						</TabContent>
					</ReferralTab>
				);
			})}
			{hasValidPermissions('health_sponsorship_referrals', [actions.UPDATE]) &&
				(process.env.CALM_ENV === 'dev' || partner.is_sales_trial) && (
					<FillButton onPress={async () => await fillAllForms(allHealthReferralFormProps, isDependentGroup)}>
						Fill Default Values
					</FillButton>
				)}
		</MarginDiv>
	);
}

function ReferralTab({
	isSelected,
	onClick,
	children,
}: {
	isSelected: boolean;
	onClick: () => void;
	children: ReactNode;
}): ReactElement {
	const backgroundColor = isSelected ? 'white' : 'gray0';
	const textColor = isSelected ? 'black' : 'blackAlpha60';
	return (
		<StyledButton
			backgroundColor={backgroundColor}
			textColor={textColor}
			onPress={onClick}
			$isSelected={isSelected}
		>
			{children}
		</StyledButton>
	);
}

export default ReferralTabs;
